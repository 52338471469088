@use '../../styleguide/colors.scss';
@use '../../styleguide/vars.scss';

.header {
  background-color: colors.$homeHeaderBg;
}

.headerContainer {
  height: 50px;
  justify-content: center;
  position: relative;
  @media screen and (min-width: vars.$extra-extra-large) {
    height: 100px;
  }
}

.hamburger {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  @media screen and (min-width: vars.$extra-large) {
    display: none;
  }

  a {
    color: colors.$white;
    display: flex;
  }
}

.logo {
  display: flex;
  justify-content: center;
  @media screen and (min-width: vars.$extra-extra-large) {
    justify-content: flex-start;
  }
}

.logo img {
  @media screen and (min-width: vars.$medium) {
    width: 120px;
    height: 36px;
  }
  @media screen and (min-width: vars.$extra-extra-large) {
    width: 202px;
    height: 60px;
  }
}

.menu {
  display: flex;
  justify-content: flex-end;

  a {
    align-items: center;
    color: colors.$homeHeaderColor;
    display: flex;
    justify-content: space-between;
    text-decoration: none;
    margin-left: 15px;

    @media screen and (min-width: vars.$extra-extra-large) {
      flex-flow: column;

      &.loggedIn img,
      &.loggedIn svg {
        height: 42px;
        width: 42px;
      }
    }

    p {
      display: none;

      @media screen and (min-width: vars.$extra-extra-large) {
        display: block;
        margin-top: 5px;
      }
    }

    img {
      border-radius: 50%;
      height: 20px;
      width: 20px;
    }
  }
}

.pageLinks {
  display: none;
  @media screen and (min-width: vars.$extra-extra-large) {
    align-items: center;
    display: flex;
  }

  a {
    align-items: center;
    color: colors.$homeHeaderColor;
    display: flex;
    margin-right: 30px;
    text-decoration: none;

    svg {
      margin-right: 10px;
    }
  }

  .active {
    color: colors.$primary-color;
  }
}

.pageLink {
  &White {
    color: colors.$white;

    &.active {
      color: colors.$primary-color;
    }
  }
}

.largeLink {
  font-size: 16px;
  text-transform: capitalize;
}

.menu > svg:first-child {
  margin-right: 15px;
}

.noDailies {
  justify-content: flex-start;
}

.alignHeader {
  align-items: center;
}

.userIcons {
  align-items: center;
  display: flex;
  justify-content: flex-end;
}

.isOpen {
  position: relative;
}

.hasItems {
  .counterBadge {
    align-items: center;
    background-color: colors.$primary-color;
    border-radius: 50%;
    color: colors.$white;
    display: flex;
    font-size: 0.5rem;
    font-stretch: condensed;
    font-weight: bold;
    justify-content: center;
    max-height: 13px;
    max-width: 13px;
    min-height: 13px;
    min-width: 13px;
    position: absolute;
    left: 52%;
    top: -5px;
  }
}

.badgeCounterCountainer {
  position: relative;
}

.wishlistIcon {
  &.hasItems {
    svg {
      color: colors.$red;
    }
  }
}
